const route = [
    //首页
    {
        path: '/',
        redirect: '/index',
        component: () => import('@/views/layout.vue'),
        children: [
            {
                path: '/index',
                component: () => import('@/views/index/index.vue'),
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/down',
                component: () => import('@/views/down/index.vue'),
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/user',
                component: () => import('@/views/user/index.vue'),
                meta: {
                    requireAuth: true
                }
            },

        ]
    },
    //  登录页
    {
        path: '/login',
        component: () => import('@/views/login/index.vue'),
    },


];
export default route
