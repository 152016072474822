import Vue from 'vue'
import App from './App.vue'

import router from './router/index' //引入路由

import ElementUI from 'element-ui'; //全局引入element
import 'element-ui/lib/theme-chalk/index.css'; //全局引入element的样式

import './assets/scss/base.scss'; //引入全局样式
import './assets/theme/index.css'; //引入全局样式


import mixin from './plugins/mixin.js' //引入全局混入方法
Vue.mixin(mixin);

import http from './api/http';
Vue.prototype.$http = http;

Vue.use(ElementUI); //全局注入element

Vue.config.productionTip = false //关闭生产提示


new Vue({
    render: h => h(App),
    router,
}).$mount('#app')