import Vue from 'vue'
import Router from 'vue-router'
// 使用element-ui Message做消息提醒
import { Message } from 'element-ui';
Vue.use(Router)

//同路由不跳转
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

//定义路由路径
let routes = require('./routesMaker').default

const router = new Router({
    mode: 'history',
    routes,
    linkActiveClass: 'active',
    //页面后退时滚动到顶部
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

//路由拦截器
router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
        if (localStorage.getItem('token')) { //判断本地是否存在Token
            next();
        } else {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })

        }
    } else {
        next();
    }
    /*如果本地 存在 token 则不允许直接跳转到 登录页面*/
    if (to.fullPath == "/login") {
        if (localStorage.getItem('token')) {
            Message.error('已经登陆过了')
            next({
                path: from.fullPath
            });
        } else {
            next();
        }
    }
});


export default router
