<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    mounted () {
        const isWeChatBrowser = /micromessenger/i.test(navigator.userAgent)
        if (isWeChatBrowser) {
            alert('请勿使用微信打开本网站，否则会找不到下载的文件！')
            window.location.href = "http://jimi.el5.cn"
        }
        const screenWidth = window.innerWidth;
        if (screenWidth < 1024) {
            alert('请使用电脑访问该网站，不要使用手机！')
        }
    }
}
</script>


