let BASE_URL = ""
if (process.env.NODE_ENV == "development") {
    //开发环境
    BASE_URL = API_URL_LOCATION
} else if (process.env.NODE_ENV == "production") {
    //生产环境
    BASE_URL = API_URL_LINE
} else {
    //本地环境
    BASE_URL = API_URL_LOCATION
}
export default BASE_URL;
