//公共
import base from './base.js';





let routes = [];
let myRoutes = routes.concat(
    //公共
    base,


);
export default myRoutes;
