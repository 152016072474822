// request.js

import request from './request';

export default {
    get (url, params) {
        return request.get(url, { params });
    },

    post (url, params) {
        return request.post(url, params);
    },
};
