// mixin.js
import BASE_URL from '@/api/config.js'
export default {
    data () {
        return {
            BASE_URL: BASE_URL
        }
    },
    created () {

    },
    methods: {

    }
}

